<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
            <h1>Manage Orders</h1>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#"><i class="iconsmind-Library"></i></a></li>
                <li class="breadcrumb-item"><router-link :to="{path: '/'}">Home</router-link></li>
                <li class="breadcrumb-item active">Orders</li>
            </ol>
        </section>

        <!-- Main content -->
        <section class="content">

            <div v-if="loading" class="spinner-border spinner text-success" role="status">
                <span class="sr-only">Loading...</span>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <!-- Column -->
                        <div v-if="orderStatistics==null || orderStatistics.length==0">
                            <p>No statistics available</p>
                        </div>
                        <div v-else v-for="(orderStatData, index) in orderStatistics" :key="index" class="col-md-6 col-lg-3 col-xlg-3">
                            <div :class="'box box-inverse '+orderStatData.box_type+' pull-up'">
                                <div class="box-body">
                                    <div class="flexbox">
                                        <h1><i class="ti-ticket"></i></h1>
                                        <h3 class="font-light text-white text-right"> {{ orderStatData.total_count }} <br><span class="font-size-14"> {{ orderStatData.name }} </span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Column -->
                    </div>
                    
                    <div class="col-12">
                        <form @submit.prevent="onSubmit">
                            <div class="box bg-pale-secondary box-outline-dark">
                                <div class="box-body">
                                    <div class="contact-page-aside">
                                        <ul class="list-style-none list-inline font-size-16">
                                            <li>
                                                <div class="form-group">
                                                    <div class="controls">
                                                        <input v-model="orderID" type="text" name="text" class="form-control" placeholder="Order ID">
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-group">
                                                    <select v-model="status" class="form-control">
                                                        <option value="">Select Status</option>
                                                        <option value="pending">Pending</option>
                                                        <option value="unpaid">Unpaid</option>
                                                        <option value="paid">Paid</option>
                                                        <option value="delivered">Delivered</option>
                                                        <option value="completed">Completed</option>
                                                    </select>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-group">
                                                    <div class="controls">
                                                        <input v-model="name" type="text" name="name" class="form-control" placeholder="Name">
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-group">
                                                    <div class="controls">
                                                        <input v-model="email" type="text" name="email" class="form-control" placeholder="Email">
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-group">
                                                    <div class="controls">
                                                        <input v-model="phone" type="text" name="phone" class="form-control" placeholder="Phone">
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-group">
                                                    <div class="controls">
                                                        <label>From</label>
                                                        <input v-model="from_date" type="date" class="form-control"/>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="form-group">
                                                    <div class="controls">
                                                        <label>To</label>
                                                        <input v-model="to_date" type="date" class="form-control"/>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="box-label float-right"></li>
                                        </ul>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <button class="btn btn-primary text-white mt-10" @click="loadOrders">Search</button>
                                        <button href="#" class="btn btn-outline btn-danger btn-sm" @click="clearSearch">Clear Search</button>
                                    </div>
                                    
                                </div>
                            </div>
                            <!-- /. box -->
                        </form>
                    </div>
                    <!-- /.col -->



                    <div class="col-12">
                        <form @submit.prevent="onSubmit">
                            <div class="box bg-pale-secondary box-outline-dark">
                                <div class="box-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <select v-model="batchOrderStatus" class="form-control">
                                                    <option value="pending">Pending</option>
                                                        <option value="unpaid">Unpaid</option>
                                                        <!-- <option value="paid">Paid</option> -->
                                                        <option value="delivered">Delivered</option>
                                                        <option value="completed">Completed</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div><br>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <button @click="updateBatchOrder" class="btn btn-primary">Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /. box -->
                        </form>
                    </div>




                    <!--<div class="col-12">
                        <form @submit.prevent="onSubmit">
                            <div class="box bg-pale-secondary box-outline-dark">
                                <div class="box-body">
                                    <div class="contact-page-aside">
                                        <ul class="list-style-none list-inline font-size-16">
                                            <li>
                                                <div class="form-group">
                                                    <label>Update Multiple Orders</label>
                                                    <select v-model="status" class="form-control">
                                                        <option value="">Select Status</option>
                                                        <option value="pending">Pending</option>
                                                        <option value="unpaid">Unpaid</option>
                                                        <option value="paid">Paid</option>
                                                        <option value="delivered">Delivered</option>
                                                        <option value="completed">Completed</option>
                                                    </select>
                                                </div>
                                            </li>
                                        </ul>
                                        <button class="btn btn-primary text-white mt-10" @click="updateOrders">Update</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>-->
                    <!-- /.col -->
























                    
                    <div class="box box-body px-0">						
                        <div class="table-responsive">
                            <div class=" float-right">
                                <button @click="requestExport" class="btn btn-sm btn-success">Export Excel<span v-if="exportLoading" class="spinner-border text-white"></span></button><br>
                            </div>
                            <div class=" float-right">
                                <a  v-if="downloadLink" :href="downloadLink" target="_blank" class="btn btn-sm btn-success">Download Excel</a>
                            </div>
                            <table class="table mt-0 table-hover no-wrap table-bordered">
                                <thead>
                                    <tr>
                                        <th>
                                            <input @change="onAllOrderChange" id="check_all_order" type="checkbox" class="filled-in">
                                            <label for="check_all_order">Order ID</label>
                                        </th>
                                        <th>Pickup</th>
                                        <th>Delivery</th>
                                        <th>Channel</th>
                                        <th>Status</th>
                                        <th>Date Created</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody> <!-- @click="showOrderPage(order)"  -->
                                    <tr v-for="order in orderList" :key="order.orderID" style="cursor: pointer;">
                                        <td>
                                            <input @change="onOrderChecked(order.orderID)" :id="`check_order_${order.orderID}`" type="checkbox" class="filled-in" :value="order.orderID">
                                            <label :for="`check_order_${order.orderID}`">
                                                {{ order.orderID }}<span v-if="order.user_subscription" class="badge badge-pink">{{ order.user_subscription.subscription.name }}</span>
                                            </label>
                                        </td>
                                        <td>
                                            <label>Name</label>
                                            <div>{{ order.pickup_name }}</div><br>

                                            <label>Address</label>
                                            <div>{{ order.pickup_address }},</div>
                                            <div>{{ order.pickup_city ? order.pickup_city.name : '' }},</div>
                                            <div>{{ order.pickup_state ? order.pickup_state.name : '' }}.</div>
                                            <div>{{ order.pickup_phone }}</div>
                                        </td>
                                        <td>
                                            <label>Name</label>
                                            <div>{{ order.delivery_name }}</div><br>

                                            <label>Address</label>
                                            <div>{{ order.delivery_address }},</div>
                                            <div>{{ order.delivery_city ? order.delivery_city.name : '' }},</div>
                                            <div>{{ order.delivery_state ? order.delivery_state.name : '' }}.</div>
                                            <div>{{ order.delivery_phone }}</div>
                                        </td>
                                        <td>{{ order.channel ? order.channel : 'website' }}</td>
                                        <td><span :class="orderClass(order)">{{ order.status }}</span></td>
                                        <td><span>{{ formatDate(order.created_at) }}</span></td>
                                        <td>
                                            <router-link :to="{name: 'ViewOrder', params: {orderID: order.orderID}}" type="button" class="btn btn-sm btn-info-outline" data-toggle="tooltip" data-original-title="View"><i class="ti-eye" aria-hidden="true">view</i></router-link>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="3">
                                            <!-- <router-link :to="{name:'CreateOrder', params: {user_id:customerData.id, customerData: customerData}}" class="btn btn-success">Create Order</router-link> -->
                                            <p></p>
                                        </td>
                                        <td colspan="4">
                                            <div class="text-right">
                                                <ul v-if="metaData" class="pagination pagination-sm">
                                                    <li v-for="index in metaData.last_page" v-bind:class="{'active': index==Number(metaData.current_page)}" :key="index"><a href="#" @click="loadOrders(index)">{{ index }}</a></li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        <!-- /.row -->

        </section>
        <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->
</template>


<script>
import { repository } from '../../presenter';
import { mapGetters } from 'vuex';
import moment from 'moment'
// import Datepicker from 'vuejs3-datepicker';
export default {
    component: {
        // Datepicker,
    },
    mounted() {
        const datatable = document.createElement('script')
        datatable.setAttribute('src', `assets/styles/vendor_components/datatable/datatables.min.js`)
        document.head.appendChild(datatable)
        datatable.onload = function(){
            const dataTable2JS = document.createElement('script')
            dataTable2JS.setAttribute('src', `assets/js/pages/data-table.js`)
            document.head.appendChild(dataTable2JS)
        }

        const sweetAlert = document.createElement('script')
        sweetAlert.setAttribute('src', '/assets/styles/vendor_components/sweetalert/sweetalert.min.js')
        document.head.appendChild(sweetAlert)

        this.loadOrderStatistics()
        this.loadOrders()
    },
    data(){
        return{
            publicPath: process.env.BASE_URL,
            orderID: '',
            status: '',
            name: '',
            email: '',
            phone: '',
            from_date: '',
            to_date: '',
            loading: false,
            batchOrderStatus: '',
            exportLoading: false,
            downloadLink: '',
            

            orderList: [],
            orderStatistics: [],
            selectedOrders: [],
            metaData: null,
        }
    },

    methods: {
        async loadOrderStatistics() {
            const response = await repository.order.loadStatistics(null);
            console.log("order stats is ", response);
            if (response.success) {
                this.orderStatistics = response.data;
            }
        },
        clearSearch() {
            this.orderID='';
            this.status='';
            this.name='';
            this.email='';
            this.phone='';
            this.from_date='';
            this.to_date='';
            this.loadOrders();
        },
        async loadOrders(page=1) {
            const data = {
                orderID: this.orderID,
                status: this.status,
                name: this.name,
                email: this.email,
                phone: this.phone,
                from_date: this.from_date,
                to_date: this.to_date,
                
            }
            this.loading = true;
            const response = await repository.order.loadOrders(data, page);
            this.loading = false;
            if (response.success) {
                this.orderList = response.data;
                this.metaData = response.extra_data;
                this.selectedOrders = [];
                this.reRenderCheckboxes('single');
                this.reRenderCheckboxes('all');
            }
        },
        async requestExport(){
            const data = {
                orderID: this.orderID,
                status: this.status,
                name: this.name,
                email: this.email,
                phone: this.phone,
                from_date: this.from_date ? this.from_date : '',
                to_date: this.to_date ? this.to_date : '',
            }

            this.exportLoading = true;
            this.downloadLink='';
            const response = await repository.order.requestExport(data);
            this.exportLoading = false;
            if (response.success) {
                this.downloadLink = response.data.path
            }
        },
        
        orderClass(order){
            switch (order.status){
                case 'initiated':
                    return 'label label-default';
                case 'pending':
                    return 'label label-danger';
                case 'unpaid':
                    return 'label label-default';
                case 'paid':
                    return 'label label-warning';
                case 'processing':
                    return 'label label-primary';
                case 'in-transit':
                case 'in_transit':
                case 'intransit':
                    return 'label label-inverse';
                case 'delivered':
                    return 'label label-success';
                case 'incomplete':
                    return 'label label-danger';
                case 'completed':
                    return 'label label-success';
                case 'cancelled':
                    return 'label label-info';
                default:
                    return 'label label-success'
            }
        },
        formatDate(orderDate) {
            return moment(orderDate, moment.ISO_8601).format("LLL");
        },
        showOrderPage(order) {
            this.$router.push({name: 'ViewOrder', params: {orderID: order.orderID}})
        },

        onAllOrderChange(){
            var selected = [];
            const checked = document.querySelector('#check_all_order').checked
            if (checked){
                this.orderList.forEach(order=>{
                    selected.push(order.orderID);
                });
            }
            this.selectedOrders = selected;
            this.reRenderCheckboxes('all');
        },

        onOrderChecked(orderID) {
            const checked = document.querySelector(`#check_order_${orderID}`).checked
            if (checked) {
                this.selectedOrders.push(orderID)
            }else{
                const index = this.selectedOrders.indexOf(orderID);
                if (index > -1) {
                    this.selectedOrders.splice(index, 1);
                }
            }
            
            this.reRenderCheckboxes('single');
        },

        reRenderCheckboxes(from) {
            if (from=='single'){
                document.querySelector('#check_all_order').checked = this.orderList.length == this.selectedOrders.length;
            }

            if (from=='all'){
                this.orderList.forEach((order)=>{
                    document.querySelector(`#check_order_${order.orderID}`).checked = this.selectedOrders.includes(order.orderID);
                });
            }
        },

        async updateBatchOrder(){
            if (!this.batchOrderStatus){
                this.showErrorMessage('Select an order status');
                return;
            }
            const data = {
                selectedOrders: this.selectedOrders,
                status: this.batchOrderStatus,
            }
            const response = await repository.order.updateBatchOrder(data)

            console.log('response is ', response)
            if (response.success) {
                this.showSuccessMessage('Order Updated')
                this.selectedOrders = [];
                this.loadOrders(this.metaData.current_page);
                return
            }
            this.showErrorMessage(response.data)
        },
        showErrorMessage(message){
            $.toast({
                heading: 'Error',
                text: message,
                position: 'top-right',
                loaderBg: '#ff6849',
                icon: 'danger',
                hideAfter: 3000,
                stack: 6
            });
        },
        showSuccessMessage (message) {
            swal(message, "", "success")
        },
    }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
.spinner {
  display: block;
  position: fixed;
  width: 3rem; 
  height: 3rem;
  z-index: 1031; /* High z-index so it is on top of the page */
  top: calc( 50% - ( 3rem / 2) ); /* where ... is the element's height */
  right: calc( 50% - ( 3rem / 2) ); /* where ... is the element's width */
}
.active {
    color: #fff;
    border: 1px solid #145388;
    background-color: #145388;
}
[type=checkbox]+label {
    padding-left: 1.8rem;
}
</style>
